import React from 'react';

import { StaticQuery, graphql } from 'gatsby';

const query = graphql`
  query {
    strapiCategory(strapiId: { eq: 1 }) {
      id
      name
      restaurants {
        id
        name
        description
      }
    }
  }
`;

const IndexPage = () => (
  <StaticQuery
    query={query}
    render={data => (
      <div>
        <h1>{data.strapiCategory.name}</h1>
        <ul>
          {data.strapiCategory.restaurants.map(restaurant => (
            <li key={restaurant.id}>{restaurant.name}</li>
          ))}
        </ul>
      </div>
    )}
  />
);

export default IndexPage;